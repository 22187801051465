import React from "react";
import Container from "../ui/Container";

const SectionTwo = () => {
  return (
    <section id="empower" className="relative py-12 md:py-24 overflow-hidden">
      <Container>
        <div className="relative">
          <div className="absolute bottom-0 right-0 mr-20 w-186 h-186 bg-gradient-to-t from-violet-900 via-darkBlue-900 to-transparent rounded-full filter blur-4xl" />
          <div className="relative">
            <h2 className="font-heading text-5xl font-medium text-white tracking-tight mb-6">
              Empower Your Crypto Journey
            </h2>
            <p className="text-xl text-gray-200 tracking-tight mb-16">
              Discover the ultimate AI-driven trading solutions with Equibot.
              Enhance your trading strategies and achieve your financial goals
              effortlessly.
            </p>
            <div className="flex flex-wrap -mx-4">
              <div className="w-full lg:w-1/3 px-4 mb-20 lg:mb-0">
                <div>
                  <img
                    className="bg-white block h-12 mb-6 p-2 rounded-lg"
                    src="/assets/images/multi strategy.png"
                    alt=" Multi-Strategy Bots"
                  />
                  <h4 className="font-heading text-3xl font-medium text-white">
                    Multi-Strategy Bots
                  </h4>
                  <div className="h-1 bg-white bg-opacity-10 my-6 rounded-md" />
                  <p className="max-w-sm text-xl tracking-tight leading-relaxed text-violet-50">
                    Leverage advanced AI algorithms to deploy multiple trading
                    strategies simultaneously, maximizing your profit potential.
                  </p>
                </div>
              </div>
              <div className="w-full lg:w-1/3 px-4 mb-20 lg:mb-0">
                <div>
                  <img
                    className="bg-white block h-12 mb-6 p-2 rounded-lg"
                    src="/assets/images/copy-trading.png"
                    alt="Copy-Trading"
                  />
                  <h4 className="font-heading text-3xl font-medium text-white">
                    Copy-Trading
                  </h4>
                  <div className="h-1 bg-white bg-opacity-10 my-6 rounded-md" />
                  <p className="max-w-xs text-xl tracking-tight leading-relaxed text-violet-50">
                    Follow the trades of expert traders and replicate their
                    success in your own portfolio with our intuitive
                    copy-trading feature.
                  </p>
                </div>
              </div>
              <div className="w-full lg:w-1/3 px-4">
                <div>
                  <img
                    className="bg-white block h-12 mb-6 p-2 rounded-lg"
                    src="/assets/images/integration.png"
                    alt="CEX & DEX Support"
                  />
                  <h4 className="font-heading text-3xl font-medium text-white">
                    CEX & DEX Support
                  </h4>
                  <div className="h-1 bg-white bg-opacity-10 my-6 rounded-md" />
                  <p className="max-w-xs text-xl tracking-tight leading-relaxed text-violet-50">
                    Enjoy seamless integration with both Centralized and
                    Decentralized Exchanges, ensuring a versatile and
                    comprehensive trading experience.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default SectionTwo;
