import React from "react";
import Container from "../ui/Container";

const CtaTwoSection = () => {
  return (
    <section className="relative py-28 bg-gradient-1 overflow-hidden">
      <Container>
        <div className="flex flex-wrap items-center -m-8">
          <div className="w-full md:w-1/2 p-8">
            <h2 className="mb-6 text-5xl text-white font-bold tracking-px-n leading-tight">
              Powering the Best Trading Community
            </h2>
            <p className="text-white text-opacity-90 font-medium md:max-w-md">
              The token allows the Equibot community to access trading tools at
              lowest cost. Stake your and reduce your trading fees up to 95%.
            </p>
          </div>
          <div className="w-full md:w-1/2 p-8">
            <div className="flex flex-wrap ml-auto md:w-56 -m-2">
              <div className="w-full p-2">
                <button
                  disabled
                  type="button"
                  className="text-center relative justify-center rounded-md border-none uppercase py-3 px-6 text-lg font-semibold block magic-button cursor-pointer disabled:cursor-default"
                >
                  <span className="align-middle flex justify-center items-center text-white">
                    Coming Soon
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default CtaTwoSection;
