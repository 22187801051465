import React from "react";
import Container from "../ui/Container";

const SectionThree = () => {
  return (
    <section id="our-trading-bots" className="pb-12 md:pb-24 pt-6">
      <Container>
        <div className="space-y-4 lg:space-y-16">
          <div className="flex flex-col lg:space-y-2">
            <h3 className="text-2xl md:text-3xl font-bold">Our Trading Bots</h3>
          </div>
          <div className="md:grid md:grid-flow-column space-y-4 md:space-y-0 md:gap-x-8 md:grid-cols-2 lg:grid-cols-3 w-full md:gap-y-4 lg:gap-y-16 pt-8 lg:pt-0">
            <div className="flex flex-col space-y-2">
              <div className="flex space-x-3 items-center">
                <div className="flex-none w-[40px] h-[40px] bg-[#5e17eb] text-white rounded-md flex justify-center items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                    className="h-5 w-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3.75 3v11.25A2.25 2.25 0 006 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0118 16.5h-2.25m-7.5 0h7.5m-7.5 0l-1 3m8.5-3l1 3m0 0l.5 1.5m-.5-1.5h-9.5m0 0l-.5 1.5M9 11.25v1.5M12 9v3.75m3-6v6"
                    />
                  </svg>
                </div>
                <h2 className="text-xl font-bold">DCA Bot</h2>
              </div>
              <div className="text-lg">
                Automatically buy and sell your tokens at preset price
                deviations.
              </div>
            </div>
            <div className="flex flex-col space-y-2">
              <div className="flex space-x-3 items-center">
                <div className="flex-none w-[40px] h-[40px] bg-[#5e17eb] text-white rounded-md flex justify-center items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                    className="h-5 w-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"
                    />
                  </svg>
                </div>
                <h2 className="text-xl font-bold">Copy Trading Bot</h2>
              </div>
              <div className="text-lg">
                Find successful traders and automatically copy their trades.
              </div>
            </div>
            <div className="flex flex-col space-y-2">
              <div className="flex space-x-3 items-center">
                <div className="flex-none w-[40px] h-[40px] bg-[#5e17eb] text-white rounded-md flex justify-center items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                    className="h-5 w-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z"
                    />
                  </svg>
                </div>
                <h2 className="text-xl font-bold">Smart Trading Terminal</h2>
              </div>
              <div className="text-lg">
                Pre-set your trades on the integrated TradingView Chart and
                Indicators.
              </div>
            </div>
            <div className="flex flex-col space-y-2">
              <div className="flex space-x-3 items-center">
                <div className="flex-none w-[40px] h-[40px] bg-[#5e17eb] text-white rounded-md flex justify-center items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                    className="h-5 w-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                    />
                  </svg>
                </div>
                <h2 className="text-xl font-bold">API Bot</h2>
              </div>
              <div className="text-lg">
                Manage all your trades with Equibot SDK. For real devs.
              </div>
            </div>
            <div className="flex flex-col space-y-2">
              <div className="flex space-x-3 items-center">
                <div className="flex-none w-[40px] h-[40px] bg-[#5e17eb] text-white rounded-md flex justify-center items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                    className="h-5 w-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15.59 14.37a6 6 0 01-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 006.16-12.12A14.98 14.98 0 009.631 8.41m5.96 5.96a14.926 14.926 0 01-5.841 2.58m-.119-8.54a6 6 0 00-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 00-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 01-2.448-2.448 14.9 14.9 0 01.06-.312m-2.24 2.39a4.493 4.493 0 00-1.757 4.306 4.493 4.493 0 004.306-1.758M16.5 9a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z"
                    />
                  </svg>
                </div>
                <h2 className="text-xl font-bold">Sniper Bot</h2>
              </div>
              <div className="text-lg">
                Be the first to buy any new token launch on Uniswap.
              </div>
            </div>
            <div className="flex flex-col space-y-2">
              <div className="flex space-x-3 items-center">
                <div className="flex-none w-[40px] h-[40px] bg-[#5e17eb] text-white rounded-md flex justify-center items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                    className="h-5 w-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15.666 3.888A2.25 2.25 0 0013.5 2.25h-3c-1.03 0-1.9.693-2.166 1.638m7.332 0c.055.194.084.4.084.612v0a.75.75 0 01-.75.75H9a.75.75 0 01-.75-.75v0c0-.212.03-.418.084-.612m7.332 0c.646.049 1.288.11 1.927.184 1.1.128 1.907 1.077 1.907 2.185V19.5a2.25 2.25 0 01-2.25 2.25H6.75A2.25 2.25 0 014.5 19.5V6.257c0-1.108.806-2.057 1.907-2.185a48.208 48.208 0 011.927-.184"
                    />
                  </svg>
                </div>
                <h2 className="text-xl font-bold">Paper Trading Bot</h2>
              </div>
              <div className="text-lg">
                Simulate your trades and learn how to be a trader without risks.
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default SectionThree;
