import React from "react";
import { Link } from "react-router-dom";
import Container from "../ui/Container";

const Footer = () => {
  return (
    <footer
      className="text-white pt-16 pb-6"
      style={{
        background: "linear-gradient(180.81deg, #07080F 0.7%, #4C515E 173.7%)",
      }}
    >
      <Container>
        <div className="space-y-4">
          <div className="flex justify-between items-center">
            <div>
              <Link to="/" className="flex items-center">
                <img
                  className="h-16 w-auto"
                  src="/assets/images/logo.png"
                  alt="..."
                />
                <span className="text-white font-black uppercase text-2xl">
                  Equibot
                </span>
              </Link>
            </div>
            <div className="flex space-x-4">
              <a
                href="https://x.com/equi_bot"
                rel="noopener noreferrer"
                target="_blank"
                className="hover:opacity-80"
              >
                <img
                  className="h-8 w-8 invert"
                  src="/assets/images/twitter.png"
                  alt="..."
                />
              </a>
            </div>
          </div>
          <div className="text-xs lg:text-sm font-normal text-gray-200 pl-5">
            Equibot makes crypto trading simple and accessible to everyone. With
            Equibot, traders can effortlessly manage and automate their orders
            on both Centralized and Decentralized Exchanges, all from a single
            location. Investors also have the option to copy successful traders
            and trade as they do, with a few clicks. Equibot offers flexible
            pricing plans, and copy-trading based on profit sharing.
          </div>
        </div>
        <div className="mt-4 text-center text-sm">
          Copyright © {/* */}2024{/* */} Equibot
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
