import React from "react";
import Container from "../ui/Container";

const CtaSection = () => {
  return (
    <section className="bg-white text-black py-16 lg:py-32">
      <Container>
        <div class="flex flex-col justify-center md:items-center space-y-6">
          <div class="space-y-2 flex flex-col justify-center md:items-center md:content-center max-w-4xl md:text-center">
            <h2 class="text-5xl lg:text-6xl font-bold">
              Advanced Trading Tools
            </h2>
            <h2 class="text-xl lg:text-2xl font-regular">
              User-driven, easy-to-use crypto trading tools with flexible
              pricing plans.
            </h2>
          </div>
          <div class="flex flex-col md:flex-row">
            <button
              disabled
              type="button"
              className="text-center relative justify-center rounded-md border-none uppercase py-3 px-6 text-lg font-semibold block magic-button cursor-pointer disabled:cursor-default"
            >
              <span className="align-middle flex justify-center items-center text-white">
                Coming soon
              </span>
            </button>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default CtaSection;
